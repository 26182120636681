import {ParticipantHandicaps, ParticipantTee, Scorecard, ScorecardScore} from '@shared/models/scorecard';
import {calculateCourseHandicapFromTee, calculateHandicapDifference} from './handicap-fn';
import {findParticipantTee, sortTeeHoles} from './tee-fn';
import {Tee} from '@shared/models/tee';
import {findParticipantHoleScore} from './hole-fn';

const scorecardToMatchPlayScoreTextParticipant = (uid: string, scorecard: Scorecard, isDormie = true) => {
    const leftPoints = scorecard.points?.[0];
    const rightPoints = scorecard.points?.[1];
    if(!leftPoints || !rightPoints) {
        return undefined;
    }

    const leftNetPoints = leftPoints.totalNetPoints;
    const rightNetPoints = rightPoints.totalNetPoints;
    if(leftNetPoints > rightNetPoints && leftPoints.uid !== uid || rightNetPoints > leftNetPoints && rightPoints.uid !== uid) {
        return undefined;
    }

    const totalHoles = Object.values(scorecard.tees![0].holes).length;
    const remainingHoles = totalHoles - leftPoints.points.length;

    return matchPlay1VS1ScoresText(leftNetPoints, rightNetPoints, remainingHoles, isDormie);
};

const isScorecardMatchPlayFinished = (scorecard: Scorecard, isDormie = true) => {
    const leftPoints = scorecard.points?.[0];
    const rightPoints = scorecard.points?.[1];
    if(!leftPoints || !rightPoints) {
        return false;
    }

    const totalHoles = Object.values(scorecard.tees![0].holes).length;
    const remainingHoles = totalHoles - leftPoints.points.length;

    return isMatchPlayFinished(leftPoints.totalNetPoints, rightPoints.totalNetPoints, remainingHoles, isDormie);
};

const isMatchPlayFinished = (leftScore: number, rightScore: number, holesRemaining: number, isDormie = true) => {
    if (leftScore === rightScore) {
        return holesRemaining === 0;
    }

    if (isDormie) {
        return leftScore > rightScore + holesRemaining || rightScore > leftScore + holesRemaining;
    }

    return holesRemaining === 0;
};

const matchPlay1VS1ScoresText = (leftScore: number, rightScore: number, holesRemaining: number, isDormie = true) => {
    if(leftScore === rightScore) {
        return 'A/S';
    }

    const scoreDifference = Math.abs(leftScore - rightScore);

    if(isDormie){
        if (leftScore > rightScore + holesRemaining || rightScore > leftScore + holesRemaining) {
            switch(scoreDifference) {
                case 0: return '';
                case 1:
                case 2:
                    return `${scoreDifference}UP`;
                default: {
                    if(holesRemaining - scoreDifference < -2) {
                        return `${scoreDifference}UP`;
                    }
                    return `${scoreDifference}&${holesRemaining}`;
                }
            }
        }
    }

    return `${scoreDifference}UP`;
};

const matchPlayScoreText = (score: number, isDormie: boolean) => {
    if (!isDormie) {
        if(score === 0) {
            return '';
        } else {
            return `${score}UP`;
        }
    }

    switch(score) {
        case 0: return '';
        case 1:
        case 2:
            return `${score}UP`;
        default: {
            const half = Math.trunc(score / 2);

            return `${half + 1}&${score % (half + 1)}`;
        }
    }
};

const calculatePlayingHandicap = (
    courseHandicap: number,
    lowestHandicap: number,
    handicapAllowance: number,
): number => {
    return (calculateHandicapDifference(courseHandicap, lowestHandicap) * handicapAllowance) / 100;
};

const adjustHandicapsForSameTee = (
    participantA: ParticipantHandicaps,
    participantB: ParticipantHandicaps,
    handicapAllowance: number,
): [ParticipantHandicaps, ParticipantHandicaps] => {
    const lowestHandicap = Math.min(participantA.courseHcp!, participantB.courseHcp!);
    const participantAPlayingHandicap = calculatePlayingHandicap(participantA.courseHcp!, lowestHandicap, handicapAllowance);
    const participantBPlayingHandicap = calculatePlayingHandicap(participantB.courseHcp!, lowestHandicap, handicapAllowance);

    return [
        {...participantA, playingHcp: participantAPlayingHandicap},
        {...participantB, playingHcp: participantBPlayingHandicap},
    ];
};

// NOT USED FOR NOW
const adjustHandicapsForDifferentTees = (
    participantA: ParticipantHandicaps,
    participantB: ParticipantHandicaps,
    participantATee: Tee,
    participantBTee: Tee,
    handicapAllowance: number,
): [ParticipantHandicaps, ParticipantHandicaps] => {
    const difference = participantATee.rating - participantBTee.rating;

    const participantAAdjustedCourseHandicap = participantA.courseHcp! + Math.max(difference, 0);
    const participantBAdjustedCourseHandicap = participantB.courseHcp! + Math.max(difference, 0);

    const lowestHandicap = Math.min(participantAAdjustedCourseHandicap, participantBAdjustedCourseHandicap);
    const participantAPlayingHandicap = calculatePlayingHandicap(participantAAdjustedCourseHandicap, lowestHandicap, handicapAllowance);
    const participantBPlayingHandicap = calculatePlayingHandicap(participantBAdjustedCourseHandicap, lowestHandicap, handicapAllowance);

    return [
        {...participantA, courseHcp: participantAAdjustedCourseHandicap, playingHcp: participantAPlayingHandicap},
        {...participantB, courseHcp: participantBAdjustedCourseHandicap, playingHcp: participantBPlayingHandicap},
    ];
};

const matchPlayCourseAndPlayingHandicaps = (
    handicapAllowance: number,
    tees: Tee[],
    participantHandicaps: ParticipantHandicaps[],
    participantTees: ParticipantTee[],
): ParticipantHandicaps[] => {
    if (participantHandicaps.length === 2) {
        const [participantA, participantB] = participantHandicaps;

        const participantATee = findParticipantTee(participantA.uid, tees, participantTees);
        const participantBTee = findParticipantTee(participantB.uid, tees, participantTees);

        const participantACourseHcp = calculateCourseHandicapFromTee(participantA.hcp, participantATee);
        const participantBCourseHcp = calculateCourseHandicapFromTee(participantB.hcp, participantBTee);

        const participantAWithCourseHcp = {...participantA, courseHcp: participantACourseHcp};
        const participantBWithCourseHcp = {...participantB, courseHcp: participantBCourseHcp};

        // if (participantATee.id === participantBTee.id) {
        return adjustHandicapsForSameTee(participantAWithCourseHcp, participantBWithCourseHcp, handicapAllowance);
        // } else {
        //     return adjustHandicapsForDifferentTees(participantAWithCourseHcp, participantBWithCourseHcp, participantATee, participantBTee, handicapAllowance);
        // }
    }

    return [];
};

const calculateOneVsOnePoints = (scores: ScorecardScore[], uid: string, type: 'net' | 'gross') => {
    const userScoreA = scores[0];
    const userScoreB = scores[1];
    const scoreA = userScoreA[type];
    const scoreB = userScoreB[type];

    if (scoreA < scoreB) {
        return userScoreA.uid === uid ? 1 : 0;
    } else if (scoreB < scoreA) {
        return userScoreB.uid === uid ? 1 : 0;
    }
    // If scores are equal, no points are awarded (hole is halved)
    return 0;
};

const findUserHolePoints = (uid: string, hole: number, type: 'net' | 'gross', scorecard: Scorecard): number => {
    const holeScores = (scorecard.scores ?? []).flatMap((score) => score.scores).filter((score) => score.hole === hole);
    if(holeScores.length === 2) {
        return calculateOneVsOnePoints(holeScores, uid, type);
    }
    return 0;
};

const addMatchPlayParticipantPoints = (uid: string, hole: number, scorecard: Scorecard): Scorecard => {
    const {points = []} = scorecard;
    const holes = sortTeeHoles(findParticipantTee(uid, scorecard.tees, scorecard.participantTees));
    const par = holes.find((h) => h.hole === hole)?.par;

    const participantHoleScore = findParticipantHoleScore(uid, hole, scorecard.scores);
    if(!par || !participantHoleScore) {
        return scorecard;
    }

    const grossPoints = findUserHolePoints(uid, hole, 'gross', scorecard);
    const netPoints = findUserHolePoints(uid, hole, 'net', scorecard);

    const participantPoints = points.find((participantPoint) => participantPoint.uid === uid) ?? {uid, points: []};
    const updatedPoints = participantPoints.points
        .filter((point) => point.hole !== hole)
        .concat({uid, par, hole, grossPoints, netPoints});

    const totalGrossPoints = updatedPoints.reduce((total, point) => total + point.grossPoints, 0);
    const totalNetPoints = updatedPoints.reduce((total, point) => total + point.netPoints, 0);

    return {
        ...scorecard,
        points: points
            .filter((participantPoint) => participantPoint.uid !== uid)
            .concat({
                ...participantPoints,
                points: updatedPoints,
                totalGrossPoints,
                totalNetPoints,
            }),
    };
};

export {
    matchPlayCourseAndPlayingHandicaps,
    addMatchPlayParticipantPoints,
    matchPlayScoreText,
    matchPlay1VS1ScoresText,
    isMatchPlayFinished,
    isScorecardMatchPlayFinished,
    scorecardToMatchPlayScoreTextParticipant,
};
