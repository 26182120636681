import {Fixture, Score} from '@shared/models/fixture';
import {FixtureRule, ScoreCalculationDirection, ScoreParticipantInput} from '@shared/models/fixture-rule';
import {Participant, ParticipantType} from '@shared/models/participant';
import {RoundPointCalculationConfig, isRoundPointCalculationConfig} from '@shared/models/score';
import {isMatchPlay, isTexasScramble, isWinLose} from './golf/fixture-rule-fn';
import {Competition} from '@shared/models/competition';
import {first} from 'lodash';

const fixtureWinner = (fixtureRule: FixtureRule, scores: Score[]) => {
    if(scores.every((score) => score.score === scores[0].score)) {
        return undefined;
    }

    if(fixtureRule.scoreCalculation === ScoreCalculationDirection.HighestScore) {
        return scores.reduce((prev, current) => (prev.score > current.score) ? prev : current);
    }

    return scores.reduce((prev, current) => (prev.score < current.score) ? prev : current);
};

const findWinners = (fixture: Fixture, scores: Score[]): Participant[] | undefined => {
    const isDraw = scores.length > 1 && scores.every((score) => score.score === scores[0].score);

    if(isDraw) {
        return undefined;
    }

    const {participants, rule} = fixture;

    if(rule.scoreCalculation === ScoreCalculationDirection.HighestScore) {
        const sortedScores = scores.sort((a, b) => b.score - a.score);
        const winningScores = sortedScores.filter((score) => score.score === sortedScores[0].score);
        return winningScores.map((score) => participants[score.participantId]);
    }

    const sortedScores = scores.sort((a, b) => a.score - b.score);
    const winningScores = sortedScores.filter((score) => score.score === sortedScores[0].score);
    return winningScores.map((score) => participants[score.participantId]);
};

const totalScore = (scores: Score[]) => scores.reduce((prev, current) => prev + current.score, 0);

const minimumNumberOfScoresInput = (fixture: Fixture) => {
    const {rule, participantType, participantsOrder} = fixture;
    if(rule.scoreInput === ScoreParticipantInput.Player && participantType === ParticipantType.Team) {
        // We allow one team to input the score if the other team doesn't show up
        return rule.playersPerTeam ?? 1;
    }
    return Object.values(participantsOrder).length;
};

const showFullTeamResults = (fixture: Fixture) => {
    const {rule, result} = fixture;
    const {scoreInput} = rule;

    if(!result || !scoreInput) {
        return false;
    }

    return !isMatchPlay(rule) && !isWinLose(rule) && !isTexasScramble(rule);
};


const getCompetitionPlayersPerFixtures = (competition: Competition) => {
    const firstStage = first(Object.values(competition.stages ?? {}));
    const firstFixtureRule = first(firstStage?.fixtureRules ?? []);

    return firstFixtureRule?.rule.playersPerTeam ?? 2;
};


const getTakeUserScores = (competition: Competition) => {
    const firstStage = first(Object.values(competition.stages ?? {}));
    const rule = first(firstStage?.fixtureRules ?? [])?.rule;
    const pointConfig = firstStage?.leaderBoard?.scoreCalculationConfigs?.filter(c => isRoundPointCalculationConfig(c)).map(c => c as RoundPointCalculationConfig).find(c => c.takeUserScores);

    return rule && isTexasScramble(rule) ? undefined : pointConfig?.takeUserScores;
};

export {fixtureWinner, findWinners, totalScore, minimumNumberOfScoresInput, showFullTeamResults, getCompetitionPlayersPerFixtures, getTakeUserScores};
