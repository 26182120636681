import {ParticipantScorecardScores} from '@shared/models/scorecard';


const findParticipantHoleScore = (uid: string, hole: number, scores: ParticipantScorecardScores[] = []) => {
    const participantScores = scores.find((participantScore) => participantScore.uid === uid);
    const participantHoleScore = participantScores?.scores?.find((score) => score.hole === hole);

    return participantHoleScore;
};

export {
    findParticipantHoleScore,
};
