import {Participant, participantIsUser} from '@shared/models/participant';
import {Tee} from '@shared/models/tee';

const getParticipantHandicap = (participant: Participant) => {
    if(participantIsUser(participant)) {
        return participant.hcp;
    }

    // Todo - implement team handicap calculation
    return undefined;
};

const handicapAllowanceString = (handicapAllowance = 100) => {
    if(handicapAllowance === 100) {
        return 'Net';
    }

    if(handicapAllowance === 0) {
        return 'Gross';
    }

    return handicapAllowance + ' %';
};


// Function to calculate course handicap
// Based on https://www.usga.org/course-handicap-calculator.html
const calculateCourseHandicap = (handicapIndex: number, courseRating: number, par: number, slopeRating = 133, holes = 18): number => {
    return Math.round(handicapIndex * (slopeRating / 113) + (courseRating - par)) * holes / 18;
};

const calculateCourseHandicapFromTee = (handicapIndex: number, tee: Tee): number => {
    return calculateCourseHandicap(handicapIndex, tee.rating, tee.courseParForTee, tee.slope, Object.values(tee.holes).length);
};

const calculatePlayingHandicapStrokePlay = (courseHandicap: number, handicapAllowance: number): number => {
    return Math.round(courseHandicap * handicapAllowance / 100);
};

// Function to calculate the difference in handicaps
const calculateHandicapDifference = (courseHandicapA: number, courseHandicapB: number): number => {
    return Math.abs(courseHandicapA - courseHandicapB);
};

export {
    getParticipantHandicap,
    handicapAllowanceString,
    calculateCourseHandicap,
    calculateCourseHandicapFromTee,
    calculatePlayingHandicapStrokePlay,
    calculateHandicapDifference,
};
