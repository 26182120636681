interface FirestoreTimestamp {
    seconds: number;
    nanoseconds: number;

    toDate(): Date;
}

function isFirestoreTimestamp(time: any): time is FirestoreTimestamp {
    if(!time) {
        return false;
    }
    return typeof time === 'object' && 'seconds' in time && 'nanoseconds' in time && typeof time.toDate === 'function';
}

const getDatabaseDate = (time: FirestoreTimestamp | Date): Date => {

    if (isFirestoreTimestamp(time)) {
        return time.toDate();
    }

    if (time instanceof Date) {
        return time;
    }

    return time;
};

export {getDatabaseDate, isFirestoreTimestamp};


export type {FirestoreTimestamp};
