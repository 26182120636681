import {Participant} from '@shared/models/participant';
import {TeamUiData} from '@shared/models/team';
import {UserUiData} from '@shared/models/user';


const participantIsTeam = (participant: Participant): participant is TeamUiData => {
    return (participant as TeamUiData).participants !== undefined;
};

const participantIsUser = (participant: Participant): participant is UserUiData => {
    return !participantIsTeam(participant);
};

const getParticipantUids = (participant: Participant): string[] => {
    if (participantIsTeam(participant)) {
        return Object.keys((participant as TeamUiData).participants);
    }
    return [participant.uid];
};

export {participantIsTeam, participantIsUser, getParticipantUids};
