import {FixtureRule, FixtureRuleType, MatchPlayFixtureRule, TexasScrambleFixtureRule, WinLoseFixtureRule} from '@shared/models/fixture-rule';

const isMatchPlay = (rule: FixtureRule) => rule.id === MatchPlayFixtureRule.id;
const isTexasScramble = (rule: FixtureRule) => rule.id === TexasScrambleFixtureRule.id;
const isTexasScrambleId = (rule: FixtureRuleType) => rule === TexasScrambleFixtureRule.id;
const isWinLose = (rule: FixtureRule) => rule.id === WinLoseFixtureRule.id;

export {
    isMatchPlay,
    isTexasScramble,
    isWinLose,
    isTexasScrambleId,
};
