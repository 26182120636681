import {CompetitionGroupFixture, Result, ResultStatus, Score} from '@shared/models/fixture';
import {FixtureRule} from '@shared/models/fixture-rule';
import {fixtureWinner} from './score';

const scoresToResult = (fixtureRule: FixtureRule, scores: Score[]): Result => {
    const winner = fixtureWinner(fixtureRule, scores);
    const isDraw = winner === undefined;

    return {
        scores,
        isDraw,
        ...(winner && {winner: winner.participantId}),
        status: ResultStatus.Pending,
    };
};

const accumulateScores = (scores: Score[]): Score[] => {
    return Object.values(scores.reduce((acc, score) => {
        const {participantId, score: currentScore} = score;
        const existingScore = acc[participantId];

        if(existingScore) {
            return {
                ...acc,
                [participantId]: {
                    participantId,
                    score: existingScore.score + currentScore,
                },
            };
        }

        return {
            ...acc,
            [participantId]: score,
        };
    }, {} as Record<string, Score>));
};

const findWinningScore = (result?: Result) => {
    if(!result || result.isDraw || !result.winners || !result.scores) {
        return undefined;
    }

    return result.scores.find(s => result.winners!.includes(s.participantId));
};

const accumulateFixtureResults = (fixtureRule: FixtureRule, a: Result, b: Result): Result => {
    const {scores: currentScores = []} = a;
    const {scores: nextScores = []} = b;

    const newScores = accumulateScores([...currentScores, ...nextScores]);

    return scoresToResult(fixtureRule, newScores);
};

const mergeFixtureResults = (fixtures: CompetitionGroupFixture[]): CompetitionGroupFixture => {
    const rule = fixtures[0].rule;

    return fixtures.reduce((acc, fixture) => {
        const {result: currentResult} = acc;
        const {result: nextResult} = fixture;

        if(!nextResult) {
            return acc;
        }

        if(!currentResult) {
            return {...acc, result: nextResult};
        }

        return {...acc, result: accumulateFixtureResults(rule, currentResult, nextResult)};
    }, {...fixtures[0], result: undefined});
};

export {mergeFixtureResults, findWinningScore};
