import {LeagueTableRowSortBy} from './league-table';
import {ParticipantType} from './participant';
import {ScoreCalculationDirection} from './fixture-rule';

enum PointCalculationTrigger {
    Fixture = 'FIXTURE',
    Round = 'ROUND',
}

interface RoundPointCalculation {
    place: number;
    points: number;
}

interface MatchPointCalculation {
    win: number;
    loss: number;
    draw: number;
}

type PointCalculationParticipantTypes = ParticipantType | 'teamFromParticipants' | 'divots';

enum HighScoreCalculationType {
    IndividualScores = 'INDIVIDUAL_SCORES',
    CumulativeScores = 'CUMULATIVE_SCORES',
    BestScore = 'BEST_SCORE',
}

const HighScoreCalculationTypeOptions = [HighScoreCalculationType.IndividualScores, HighScoreCalculationType.BestScore];

interface BasePointCalculationConfig {
    sortScoreDirection: ScoreCalculationDirection;
    sortBy: LeagueTableRowSortBy;
}

interface RoundPointCalculationConfig extends BasePointCalculationConfig {
    participantType: PointCalculationParticipantTypes;
    trigger: PointCalculationTrigger.Round;
    roundPointCalculation?: RoundPointCalculation[];
    // How many user scores do we aggregate for the team score
    takeUserScores?: number;
    // Only for High score formats
    highScoreCalculationType?: HighScoreCalculationType;
}

interface FixturePointCalculationConfig extends BasePointCalculationConfig {
    participantType: ParticipantType;
    trigger: PointCalculationTrigger.Fixture;
    matchPointCalculation: MatchPointCalculation;
}

type PointCalculationConfig = RoundPointCalculationConfig | FixturePointCalculationConfig;

const isRoundPointCalculationConfig = (config: PointCalculationConfig): config is RoundPointCalculationConfig => {
    return config.trigger === PointCalculationTrigger.Round;
};

const isFixturePointCalculationConfig = (config: PointCalculationConfig): config is FixturePointCalculationConfig => {
    return config.trigger === PointCalculationTrigger.Fixture;
};

const isScoreSortBy = (config: PointCalculationConfig) => {
    return config.sortBy === 'score';
};

export {PointCalculationTrigger, isScoreSortBy, isRoundPointCalculationConfig, isFixturePointCalculationConfig, HighScoreCalculationTypeOptions, HighScoreCalculationType};

export type {RoundPointCalculation, RoundPointCalculationConfig, PointCalculationConfig, MatchPointCalculation, PointCalculationParticipantTypes, FixturePointCalculationConfig};
